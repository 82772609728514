:root {
	--bg-color: black;
	--panel-bg-color: gray;
	--header-color: black;
	--text-color: white;
	--list-color: black;
	--link-color: white;
	--label-color: black;
	--message-padding: 10px;
	--message-margin: 10px;
	--disabled-bg-color: gray;
}

button:disabled {

	background-color: var(--disabled-bg-color);

}

.profilePic {
	width: 100px;
	height: 100px;
}

.iconPic {
	width: 30px;
	height: 30px;
}

.buttonLink {
	background: none!important;
	border: none;
	padding: 0!important;
	/*optional*/
	font-family: arial, sans-serif;
	/*input has OS specific font-family*/
	color: white;
	text-decoration: underline;
	cursor: pointer;
}

.confirmButton {
	background: red;
	color: white;
}

.cancelButton {
	background: green;
	color: white;
}

.message {
	background-color: green;
	color: white;
	margin: var(--message-margin);
	padding: var(--message-margin);
}

.errorMessage {
	background-color: red;
	color: white;
	margin: var(--message-margin);
	padding: var(--message-margin);
}

.warningMessage {
	background-color: yellow;
	color: red;
	margin: var(--message-margin);
	padding: var(--message-margin);
}

p {
	color: var(--text-color);
}

body {
	background-color: var(--bg-color);
	/*color:white;*/
	font-family: sans-serif;
}

h1, h2, h3, h4, h5 {
	color: var(--header-color);
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

ul {
	list-style-type: disc;
	color: var(--list-color);
}

legend {
	color: black;
	font-weight: bold;
}

hr {
	background-color: var(--text-color);
	height: 2px;
}

.content {
	/*width: 100%;*/
	background-color: var(--panel-bg-color);
	padding: 10px 10px 10px 10px;
	font-size: 0.8em;
	border-radius: 8px;
	max-width: 500px;
	margin: auto;
	font-family: Arial;
	color: var(--text-color);
}

.titleContent {

	color:white;	
	background-color: black;

}

h2.titleContent {

	color:white;

}

.video {
	width: 90%;
	height: 245px;
	margin-left: auto;
	margin-right: auto;
	display: block;
}

.small-video {
	width: 90%;
	height: 245px;
	margin-left: auto;
	margin-right: auto;
	display: block;
}

a:visited {
	color: var(--link-color);
}

a:link {
	color: var(--link-color);
}

table {
	border: solid 1px;
	border-color: black;
	border-collapse: collapse;
	width: 100%;
}

th {
	border: solid 1px;
	border-color: black;
	margin: 0px;

	padding: 5px;
	background-color: black;
	color: white;
	font-weight: normal;
}

th.verticalHeader {
	border: 0px;
	border-color: black;
	text-align: right;
	border-bottom-color: white;
}

th.right {
	border-right: black solid 1px;
}

td {
	border: solid 1px;
	border-color: black;
	padding:5px;
	margin: 0px;
}

.error {
	background-color: red;
	color: white;
	padding: 5px;
}

input[type="submit"] {
	background-color: black;
	color: white;
	padding: 4px;
	border: none;
	cursor: pointer;
}

input[type="text"] {

	width: 97%;

}

select {
	padding: 4px;
}

label {
	color: var(--label-color)
}

button {
	background-color: black;
	color: white;
	border: none;
	padding: 5px;
}

.loadingIcon {

	height: 40px;
	width: 40px;
	margin-left: auto;
  	margin-right: auto;
	display: block;

}